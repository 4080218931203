<template>
  <div>
    <div v-if="apiLoaded">
      <div v-if="infoFound">
        <div>
          Регистрационный номер: {{notification.regnum}}
        </div>
        <div>
          Заказчик: {{notification.customer.fullName}}
        </div>
        <div>
          Тема: {{notification.theme}}
        </div>
        <div>
          Начальная цена: {{notification.maxPrice}} р.
        </div>
      </div>
      <div v-else>Информация не найдена или указано несколько закупок</div>
    </div>
    <div v-else>Загрузка информации о закупке...</div>
  </div>
</template>

<script>
export default {
  name: "NotificationInfo",
  props: ["url"],
  data() {
    return {
      notification: null,
      infoFound: null,
      apiLoaded: false,
    };
  },
  async beforeMount() {
    if (this.url == null || this.url === "") {
      this.infoFound = null;
      this.apiLoaded = true;
      return;
    }

    let index = this.url.indexOf("?regNumber=");
    if (index < 1) {
      this.infoFound = null;
      this.apiLoaded = true;
      return;
    }

    let regnumber = this.url.substring(index + 11)
    if(regnumber.length < 8){
      this.infoFound = null;
      this.apiLoaded = true;
      return;
    }

    let req = await fetch("https://sup.nisse.ru/getNotificationByRegnum?regnum=" + regnumber)
    // console.log("https://sup.nisse.ru/getNotificationByRegnum? regnum=" + regnumber)
    if(!req.ok){
      this.infoFound = null;
      this.apiLoaded = true;
      return;
    }

    try{
    this.notification = await req.json()
    this.infoFound = true
    this.apiLoaded = true
    } catch {
      this.infoFound = false
      this.apiLoaded = true
    }
  },
};
</script>

<style scoped>
</style>